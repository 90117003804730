import React from "react";
import HomePage from "./pages/HomePage";
import "./styles/style.css";

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;

function BackgroundTest() {
  return (
    <div
      style={{
        backgroundImage: "url('/images/b0e11218-879b-4bce-a681-a498b5a30b88.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "300px",
        width: "100%",
        border: "3px solid red",
      }}
    >
      <h1 style={{ color: "white", padding: "20px" }}>If you see this, it's working ✅</h1>
    </div>
  );
}
